import React, { useEffect } from 'react'
import { useStaticQuery, graphql, navigate } from 'gatsby'
import styled, { css } from 'styled-components'
import Img from 'gatsby-image'
import { Link } from 'gatsby'

import Nav from '../../components/nav'
import SEO from '../../components/seo'
import Footer from '../../components/footer'
import Box from '../../components/box'
import Image from '../../components/image'
import RowLayout from '../../components/rowLayout'
import TabPickerInverted from '../../components/tabPickerInverted'
import IntegrationCard from '../../components/integrationCard'
import CompanyIcons from '../../components/companyIcons'
import Hidden from '../../components/hidden'
import Accordion from '../../components/accordion'
import AccordionSingleSelect from '../../containers/accordionSingleSelect'
import checkUtmParams from '../../utils/checkUtmParams'

import useHotjarScript from '../../utils/hooks/useHotjarScript'
import { CheckMarkIcon } from '../../icons'
import { camelCase } from 'lodash'
import IntegrationSection from '../../components/integrationsSection'
import CloudAcademySection from '../../components/cloudAcademySection'
import HeroVideoBackground from '../../components/heroVideoBackground'
import Button from '../../components/button'
import TeamSection from '../../components/teamSection'
import FaqSection from '../../components/faqSection'
import { InnerSection, Section } from '..'
import BookCallModal from '../../components/bookCallModal'
import HeroVideo from '../../components/heroVideo'

const MarketingPage = () => {
  const data = useStaticQuery(
    graphql`
      query {
        
        organizeVideos: file(
          relativePath: { eq: "1.3/organize-videos.png" }
        ) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        marketingVideoCards: file(
          relativePath: { eq: "1.3/marketing-video-cards.png" }
        ) {
          childImageSharp {
            fixed(width: 328, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        leadershipPostCards: file(
          relativePath: { eq: "1.3/leadership-post-cards.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 280, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        salesVideoReader: file(
          relativePath: { eq: "1.3/sales-video-reader.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 280, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        usersCaughtUp: file(
          relativePath: { eq: "1.3/users-caught-up.png" }
        ) {
          childImageSharp {
            fixed(height: 37, width: 193, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        marketingStreams: file(
          relativePath: { eq: "1.3/marketing-streams.png" }
        ) {
          childImageSharp {
            fixed(width: 328, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        marketingMobileReader: file(
          relativePath: { eq: "1.3/marketing-mobile-reader.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 300, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )

  const integrations = []

  const toExtensionDownload = () => {
    navigate('browser-extension')
  }

  useEffect(() => {
    checkUtmParams()
  }, [])

  useHotjarScript()

  const [isBookCallModalOpen, setIsBookCallModalOpen] = React.useState(false)

  return (
    <>
      <Nav />
      <BookCallModal
        open={isBookCallModalOpen}
        onClose={() => {
          setIsBookCallModalOpen(false)
        }}
      />
      <Box>
        <SEO
          title="Pulse - Message boards for modern teams"
          description="Pulse is a platform for team alignment. Engineered to help modern teams stay more connected and aligned by communicating more openly and asynchronously."
          ogImage="/assets/ogimages/pulse.png"
          twImage="/assets/ogimages/twitter/pulse.png"
        />
        <HeroVideoBackground className='-top-[116px] tabletLToLower:top-[368px]' />
        <div
          className='mt-[64px]'
        >
          <Section>
            <InnerSection className='flex justify-between pt-48 pb-[121.5px] tabletLToLower:pb-[40px] tabletLToLower:flex-col'>
              <div
                className='w-[496px] tabletLToLower:w-full pt-[30px]'
              >
                {/* <div
                  className='text-base leading-22 text-gray-foreground-400 tabletLToLower:w-full tabletLToLower:text-center'
                >
                  Accelerate training, onboarding and sales.
                </div> */}
                <div className='font-display mt-16 leading-[64px] text-[60px] tabletLToLower:text-4xl tabletLToLower:leading-[42px] tabletLToLower:text-center'>
                  Marketing
                </div>
                <div className='mt-16 text-gray-foreground font-display tabletLToLower:text-center tabletLToLower:text-base tabletLToLower:leading-[20px] max-w-[420px] text-xl leading-24'>
                  Make customer insights more discoverable, increase visibility of marketing work and keep stakeholders in the know.
                </div>
                <div className='mt-24 flex tabletLToLower:justify-center'>
                  <Button to='/signup/' className='px-18'>
                    <span className='tabletLToLower:hidden block'>
                      Try Pulse for free
                    </span>
                    <span className='tabletLToLower:block hidden'>
                      Create an account
                    </span>
                  </Button>
                  <Button className='ml-12 px-18 tabletLToLower:!hidden' secondary onClick={() => setIsBookCallModalOpen(true)}>
                    Get Demo
                  </Button>
                </div>
              </div>
              <HeroVideo />
            </InnerSection>
          </Section>
          <Section
            className='flex justify-center text-center pt-96 tabletLToLower:pt-64 pb-12'
          >
            <InnerSection style={{ width: '650px' }} className='tabletLToLower:!w-full'>
              <div className='text-5xl leading-52 font-display tabletLToLower:text-4xl tabletLToLower:leading-[42px] text-center'>
                Make everyone part of your marketing process
              </div>
              <div className='text-gray-foreground leading-24 text-xl mt-16 flex justify-center tabletLToLower:text-base tabletLToLower:leading-[20px]'>
                <div style={{ width: '496px' }} className='tabletLToLower:!w-full'>
                  With Pulse, marketers can make their process more transparent by sharing progress, initiatives, and user research, so they can get feedback from stakeholders asynchronously and go to market with more confidence than before.
                </div>
              </div>
            </InnerSection>
          </Section>
          <Section
            className='grid place-content-center pt-48 tabletLToLower:pt-32'
          >
            <div
              className='grid-cols-2 gap-x-16 gap-y-24 grid tabletLToLower:grid-cols-1'
            >
              <div className='bg-gray-foreground-100 rounded-2xl pt-24 px-18 w-[328px] tabletLToLower:w-full h-[400px] text-center'>
                <div className='flex justify-center text-center'>
                  <div
                    className='rounded-full text-2xl leading-30 border-[1px] border-solid border-black box-border h-[44px] w-[44px] py-7 font-display'
                    style={{
                      fontFeatureSettings: `'pnum' on, 'onum' on`
                    }}
                  >
                    01
                  </div>
                </div>
                <div className='mt-12 text-xl leading-26 font-display'>
                  Organize marketing content
                </div>
                <div className='mt-8 text-base leading-22 text-gray-foreground'>
                  Instantly transcribed & searchable.
                </div>
                <div className='mt-[5px] grid place-content-center'>
                  <Img
                    fadeIn={false}
                    fixed={data.marketingVideoCards.childImageSharp.fixed}
                  />
                </div>
              </div>

              <div className='bg-gray-foreground-100 rounded-2xl pt-24 px-18 w-[328px] tabletLToLower:w-full h-[400px] text-center overflow-hidden'>
                <div className='flex justify-center text-center'>
                  <div
                    className='rounded-full text-2xl leading-30 border-[1px] border-solid border-black box-border h-[44px] w-[44px] py-7 font-display'
                    style={{
                      fontFeatureSettings: `'pnum' on, 'onum' on`
                    }}
                  >
                    02
                  </div>
                </div>
                <div className='mt-12 text-xl leading-26 font-display'>
                  Share customer insights
                </div>
                <div className='mt-8 text-base leading-22 text-gray-foreground'>
                  Watch marketing meetings at 2x.
                </div>
                <div className='mt-[25.9px]'>
                  <Img
                    fadeIn={false}
                    fluid={data.salesVideoReader.childImageSharp.fluid}
                  />
                </div>
              </div>

              <div className='bg-gray-foreground-100 rounded-2xl pt-24 px-18 w-[328px] tabletLToLower:w-full h-[400px] text-center overflow-hidden'>
                <div className='flex justify-center text-center'>
                  <div
                    className='rounded-full text-2xl leading-30 border-[1px] border-solid border-black box-border h-[44px] w-[44px] py-7 font-display'
                    style={{
                      fontFeatureSettings: `'pnum' on, 'onum' on`
                    }}
                  >
                    03
                  </div>
                </div>
                <div className='mt-12 text-xl leading-26 font-display'>
                  Fasten onboarding
                </div>
                <div className='mt-8 text-base leading-22 text-gray-foreground'>
                  Ramp up new salespeople, faster.
                </div>
                <div className='mt-[14px] grid place-content-center overflow-hidden'>
                  <Img
                    fadeIn={false}
                    fixed={data.marketingStreams.childImageSharp.fixed}
                  />
                </div>
              </div>

              <div className='bg-gray-foreground-100 rounded-2xl pt-24 px-18 w-[328px] tabletLToLower:w-full h-[400px] text-center overflow-hidden'>
                <div className='flex justify-center text-center'>
                  <div
                    className='rounded-full text-2xl leading-30 border-[1px] border-solid border-black box-border h-[44px] w-[44px] py-7 font-display'
                    style={{
                      fontFeatureSettings: `'pnum' on, 'onum' on`
                    }}
                  >
                    04
                  </div>
                </div>
                <div className='mt-12 text-xl leading-26 font-display'>
                  Catch up on the go.
                </div>
                <div className='mt-8 text-base leading-22 text-gray-foreground'>
                  Sales content available 24/7.
                </div>
                <div className='mt-[25px] flex justify-center'>
                  <Img
                    fluid={data.marketingMobileReader.childImageSharp.fluid}
                    fadeIn={false}
                    style={{
                      width: '188px'
                    }}
                  />
                </div>
              </div>

            </div>
          </Section>
          {/* <div className='mt-[144px] tabletLToLower:mt-64' />
          <Section>
            <InnerSection className='pt-48 px-104 bg-gray-foreground-100 tabletLToLower:hidden' style={{ borderRadius: '32px' }}>
              <div className='flex justify-center'>
                <div className='text-5xl leading-52 w-640 text-center font-display'>
                  Organize your sales training material in one place.
                </div>
              </div>
              <div className='flex justify-center'>
                <div className='mt-16 text-gray-foreground text-xl leading-24 text-center w-496'>
                  Don’t get bogged down in endless chat and unnecessary meetings. One place to share share engineering videos and udaptes.
                </div>
              </div>
              <div className='mt-48'>
                <Img
                  fluid={data.organizeVideos.childImageSharp.fluid}
                  fadeIn={false}
                />
              </div>
            </InnerSection>
          </Section> */}
          <div className='mt-[192px] tabletLToLower:mt-64' />
          <IntegrationSection />
          <FaqSection />
        </div>
        <Footer />
      </Box>
    </>
  )

}

export default MarketingPage
